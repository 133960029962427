import * as awrtc from "../awrtc/index"
import {IMediaStream} from "../awrtc/media_browser/IMediaStream";
let jsEnv = require("browser-or-node");

/**
 * Main (and most complicated) example for using BrowserWebRtcCall.
 * Have a look at examples.html for easier scenarios.
 * 
 * 
 * 
 * Features:
 * - Build a "Join" system on top of the regular Listen / Call model to make it easier to use. 
 * - basic user interface (This is for easy testing not for use as a final application!!! Write your own using the API)
 * - setup to be compatible with the Unity Asset's CallApp (but without TURN server!)
 * - Get parameters from the address line to configure the call
 * - autostart the call (this might not work in all browsers. Mostly used for testing)
 * Todo:
 * - text message system (so far it sends back the same message)
 * - conference call support 
 * 
 * 
 */
export class CallApp
{
    public mAddress;
    public static mVideoAddressAttribute = "data-webrtc-address";
    private mNetConfig = new awrtc.NetworkConfig();
    private mCall : awrtc.BrowserWebRtcCall = null;

    public static signalingWebsocketServerUrl: string = "wss://stream.lab-api.cosentios.com/broadcastapp";

    private mIntervalId:any = -1;

    private mLocalVideo: HTMLVideoElement = null;
    private mRemoteVideo = {};
    private mediaStream;
    private messageHandler;
    
    private mIsRunning = false;

    public static tag = ">> CallApp::";

    public constructor(iceServers, mediaStream:IMediaStream=null, messageHandler=null)
    {
        this.mNetConfig.IceServers = iceServers;
        this.mNetConfig.IsConference = true;
        this.mNetConfig.SignalingUrl = CallApp.signalingWebsocketServerUrl;
        this.mediaStream = mediaStream;
        this.messageHandler = messageHandler;
    }

    
    private tobool(value, defaultval)
    {
        if(value === true || value === "true")
            return true;
        if(value === false || value === "false")
            return false;

        return defaultval;
    }

    public Start(address, audio, video) : void
    {
        if(this.mCall != null)
            this.Stop();
        
        this.mIsRunning = true;
        this.Ui_OnStart()
        console.debug(CallApp.tag, "start");
        console.debug(CallApp.tag, "Using signaling server url: " + this.mNetConfig.SignalingUrl);
        //create media configuration
        let config = new awrtc.MediaConfig();
        config.Audio = audio;
        config.Video = video;
        config.IdealWidth = 640;
        config.IdealHeight = 480;
        config.IdealFps = 30;
        
        //For usage in HTML set FrameUpdates to false and wait for  MediaUpdate to
        //get the VideoElement. By default awrtc would deliver frames individually
        //for use in Unity WebGL
        console.debug(CallApp.tag, "requested config:" + JSON.stringify(config));
        //setup our high level call class.
        this.mCall = new awrtc.BrowserWebRtcCall(this.mNetConfig, this.mediaStream);

        //handle events (get triggered after Configure / Listen call)
        //+ugly lambda to avoid loosing "this" reference
        this.mCall.addEventListener((sender, args)=>{
            this.OnNetworkEvent(sender, args);
        });



        //As the system is designed for realtime graphics we have to call the Update method. Events are only
        //triggered during this Update call!
        this.mIntervalId = setInterval(()=>{
            this.Update();
        }, 50);


        //configure media. This will request access to media and can fail if the user doesn't have a proper device or
        //blocks access
        this.mCall.Configure(config);

        //Try to listen to the address 
        //Conference mode = everyone listening will connect to each other
        //Call mode -> If the address is free it will wait for someone else to connect
        //          -> If the address is used then it will fail to listen and then try to connect via Call(address);
        this.mCall.Listen(address);
        
    }

    
    
    public Stop(): void
    {
        this.Cleanup();
    }

    private Cleanup():void{

        if(this.mCall != null)
        {
            this.mCall.Dispose();
            this.mCall = null;
            clearInterval(this.mIntervalId);
            this.mIntervalId = -1;
            this.mIsRunning = false;
            this.mLocalVideo = null;
            this.mRemoteVideo = {};
        }
        this.Ui_OnCleanup();
    }

    public GetConnectionIds() : Array<Number> {
        return this.mCall.GetConnectionInfo().GetIds();
    }

    public GetCall() : awrtc.BrowserWebRtcCall {
        return this.mCall;
    }

    public GetCallConnectionIds() : awrtc.BrowserWebRtcCall {
        return this.mCall;
    }


    private Update():void
    {
        if(this.mCall != null)
            this.mCall.Update();
    }

    private OnNetworkEvent(sender: any, args: awrtc.CallEventArgs):void{

        //User gave access to requested camera/ microphone
        if (args.Type == awrtc.CallEventType.ConfigurationComplete){
            console.debug(CallApp.tag, "OnNetworkEvent: configuration complete");
        }
        else if (args.Type == awrtc.CallEventType.MediaUpdate) {
            
            let margs = args as awrtc.MediaUpdatedEventArgs;
            if (this.mLocalVideo == null && margs.ConnectionId == awrtc.ConnectionId.INVALID) {

                let videoElement = margs.VideoElement;
                videoElement.setAttribute(CallApp.mVideoAddressAttribute, this.mAddress);
                this.mLocalVideo = videoElement;
                this.Ui_OnLocalVideo(videoElement);
                console.debug(CallApp.tag, "local video added resolution:" + videoElement.videoWidth  + videoElement.videoHeight + " fps: ??");
            }
            else if (margs.ConnectionId != awrtc.ConnectionId.INVALID && this.mRemoteVideo[margs.ConnectionId.id] == null) {
                
                let videoElement = margs.VideoElement;
                this.mRemoteVideo[margs.ConnectionId.id] = videoElement;
                this.Ui_OnRemoteVideo(videoElement, margs.ConnectionId);
                console.debug(CallApp.tag, "remote video added resolution:" + videoElement.videoWidth  + videoElement.videoHeight + " fps: ??");
            }
        }
        else if (args.Type == awrtc.CallEventType.ListeningFailed) {
            //First attempt of this example is to try to listen on a certain address
            //for conference calls this should always work (expect the internet is dead)
            if (this.mNetConfig.IsConference == false) {
                //no conference call and listening failed? someone might have claimed the address.
                //Try to connect to existing call
                this.mCall.Call(this.mAddress);
            }
            else {
                let errorMsg = "Listening failed. Offline? Server dead?";
                console.error(CallApp.tag + errorMsg);
                this.Ui_OnError(errorMsg);
                this.Cleanup();
                if (jsEnv.isBrowser) {
                        const event = new CustomEvent('webrtc-connection-failed');
                    document.dispatchEvent(event);
                }
                return;
            }
        }
        else if (args.Type == awrtc.CallEventType.ConnectionFailed) {
            //Outgoing call failed entirely. This can mean there is no address to connect to,
            //server is offline, internet is dead, firewall blocked access, ...
            let errorMsg = "Connection failed. Offline? Server dead? ";
            console.error(CallApp.tag + errorMsg);
            this.Ui_OnError(errorMsg);
            this.Cleanup();
            return;
        }
        else if (args.Type == awrtc.CallEventType.CallEnded) {
            //call ended or was disconnected
            let callEndedEvent = args as awrtc.CallEndedEventArgs;
            console.debug(CallApp.tag, "call ended with id " + callEndedEvent.ConnectionId.id);
            delete this.mRemoteVideo[callEndedEvent.ConnectionId.id];
            this.Ui_OnLog("Disconnected from user with id " + callEndedEvent.ConnectionId.id);
            //check if this was the last user
            // if(this.mNetConfig.IsConference == false && Object.keys(this.mRemoteVideo).length == 0)
            // {
                //1 to 1 call and only user left -> quit
            this.Cleanup();
                // return;
            // }
            if (jsEnv.isBrowser) {
                const event = new CustomEvent('webrtc-call-ended', {
                    detail: {
                        ...callEndedEvent
                    }
                });
                document.dispatchEvent(event);
            } else if (jsEnv.isNode) {
                // disconnect viewers
                
            }
        }
        else if (args.Type == awrtc.CallEventType.Message) {
            //no ui for this yet. simply echo messages for testing
            let messageArgs = args as awrtc.MessageEventArgs;
            // this.mCall.Send(messageArgs.Content, messageArgs.Reliable, messageArgs.ConnectionId);

            if (this.messageHandler != null && typeof this.messageHandler === "function") {
                this.messageHandler(messageArgs.Content, messageArgs.Reliable, messageArgs.ConnectionId)
            }
            if (jsEnv.isBrowser) {
                console.debug(CallApp.tag, "Message in browser", messageArgs.Content);
                const event = new CustomEvent('webrtc-receive-chat-message', {
                    detail: {
                        ...messageArgs
                    }
                });
                document.dispatchEvent(event);
            } else if (jsEnv.isNode) {
                console.debug(CallApp.tag, "Message in Node", messageArgs.Content);
            }
        }
        else if (args.Type == awrtc.CallEventType.DataMessage) {
            //no ui for this yet. simply echo messages for testing
            let messageArgs = args as awrtc.DataMessageEventArgs;
            this.mCall.SendData(messageArgs.Content, messageArgs.Reliable, messageArgs.ConnectionId);
            if (jsEnv.isBrowser) {
                console.debug(CallApp.tag, "Data message in browser", messageArgs.Content);
                const event = new CustomEvent('webrtc-receive-chat-message', {
                    detail: {
                        ...messageArgs
                    }
                });
                document.dispatchEvent(event);
            } else if (jsEnv.isNode) {
                console.debug(CallApp.tag, "Data message in Node", messageArgs.Content);
            }

        }
        else if (args.Type == awrtc.CallEventType.CallAccepted) {
            let arg = args as awrtc.CallAcceptedEventArgs;
            console.debug(CallApp.tag, "New call accepted id: " + arg.ConnectionId.id);
        }
        else if (args.Type == awrtc.CallEventType.WaitForIncomingCall) {
            console.debug(CallApp.tag, "Waiting for incoming call ...");
        }
        else {
            console.debug(CallApp.tag, "Unhandled event: " + args.Type);
        }
    }


    //UI calls. should be moved out into its own class later
    private mAudio;
    private mVideo;
    private mAutostart;
    private mUiAddress: HTMLInputElement;
    private mUiAudio: HTMLInputElement;
    private mUiVideo: HTMLInputElement;
    private mUiButton: HTMLButtonElement;
    private mUiUrl: HTMLElement;
    private mUiLocalVideoParent: HTMLElement;
    private mUiRemoteVideoParent: HTMLElement;

    public setupUi(parent : HTMLElement)
    {
        this.mUiAddress = parent.querySelector<HTMLInputElement>(".callapp_address");
        this.mUiAudio = parent.querySelector<HTMLInputElement>(".callapp_send_audio");
        this.mUiVideo = parent.querySelector<HTMLInputElement>(".callapp_send_video");
        this.mUiUrl = parent.querySelector<HTMLParagraphElement>(".callapp_url");
        this.mUiButton = parent.querySelector<HTMLInputElement>(".callapp_button");
        this.mUiLocalVideoParent =  parent.querySelector<HTMLParagraphElement>(".callapp_local_video");
        this.mUiRemoteVideoParent =  parent.querySelector<HTMLParagraphElement>(".callapp_remote_video");
        this.mUiAudio.onclick = this.Ui_OnUpdate;
        this.mUiVideo.onclick = this.Ui_OnUpdate;
        this.mUiAddress.onkeyup = this.Ui_OnUpdate;
        this.mUiButton.onclick = this.Ui_OnStartStopButtonClicked;

        //set default value + make string "true"/"false" to proper booleans
        this.mAudio  = this.tobool(this.mAudio , true)
        this.mVideo  = this.tobool(this.mVideo , true);
        this.mAutostart = this.tobool(this.mAutostart, false);

        if(this.mAddress === null)
            this.mAddress = this.GenerateRandomKey();
        this.Ui_Update();


        //used for interacting with the Unity CallApp

        //current hack to get the html element delivered. by default this
        //just the image is copied and given as array
        //Lazy frames will be the default soon though


        if(this.mAutostart)
        {
            console.debug(CallApp.tag, "Starting automatically ... ")
            this.Start(this.mAddress, this.mAudio , this.mVideo );
        }

        console.debug(CallApp.tag, "address: " + this.mAddress + " audio: " + this.mAudio  + " video: " + this.mVideo  + " autostart: " + this.mAutostart);
    }


    public setupUiFromCallback(parentCallback)
    {
        // @ts-ignore
        this.mUiAddress = parentCallback.getUiAddress();
        // @ts-ignore
        this.mUiAudio = parentCallback.getUiAudio();
        // @ts-ignore
        this.mUiVideo = parentCallback.getUiVideo();
        // @ts-ignore
        this.mUiUrl = parentCallback.getUiUrl();
        // @ts-ignore
        this.mUiButton = parentCallback.getUiButton();
        // @ts-ignore
        this.mUiLocalVideoParent =  parentCallback.getUiLocalVideoParent();
        // @ts-ignore
        this.mUiRemoteVideoParent =  parentCallback.getUiRemoteVideoParent();
        this.mUiAudio.onclick = this.Ui_OnUpdate;
        this.mUiVideo.onclick = this.Ui_OnUpdate;
        this.mUiAddress.onkeyup = this.Ui_OnUpdate;
        this.mUiButton.onclick = this.Ui_OnStartStopButtonClicked;

        this.Ui_OnUpdate();
        //set default value + make string "true"/"false" to proper booleans
        this.mAudio  = this.tobool(this.mAudio , true)
        this.mVideo  = this.tobool(this.mVideo , true);
        this.mAutostart = this.tobool(parentCallback.autoStart, false);

        if(this.mAddress === null)
            this.mAddress = this.GenerateRandomKey();
        this.Ui_Update();

        //used for interacting with the Unity CallApp

        //current hack to get the html element delivered. by default this
        //just the imag e is copied and given as array
        //Lazy frames will be the default soon though


        if(this.mAutostart)
        {
            console.debug(CallApp.tag, "Starting automatically ... ")
            this.Start(this.mAddress, this.mAudio , this.mVideo );
        }

        console.debug(CallApp.tag, "address: " + this.mAddress + " audio: " + this.mAudio  + " video: " + this.mVideo  + " autostart: " + this.mAutostart);
    }
    private Ui_OnStart(){
        this.mUiButton.textContent = "Stop";
    }
    private Ui_OnCleanup()
    {
        console.debug(CallApp.tag, ">> callapp:: Ui_OnCleanup")
        this.mUiButton.textContent = "Join";
        if (this.mUiLocalVideoParent) {
            while (this.mUiLocalVideoParent.hasChildNodes()) {
                this.mUiLocalVideoParent.removeChild(this.mUiLocalVideoParent.firstChild);
            }
        }
        if (this.mUiRemoteVideoParent) {
            while (this.mUiRemoteVideoParent.hasChildNodes()) {
                this.mUiRemoteVideoParent.removeChild(this.mUiRemoteVideoParent.firstChild);
            }
        }

    }
    private Ui_OnLog(msg:string){

    }
    private Ui_OnError(msg:string){

    }
    private Ui_OnLocalVideo(video : HTMLVideoElement){
        if (this.mUiLocalVideoParent) {
            this.mUiLocalVideoParent.appendChild( document.createElement("br"));
            this.mUiLocalVideoParent.appendChild(video);
        }
    }

    private Ui_OnRemoteVideo(video : HTMLVideoElement, id: awrtc.ConnectionId){

        console.debug(CallApp.tag, "Ui_OnRemoteVideo")
        if (this.mUiRemoteVideoParent) {
            let existingVideoElement = this.mUiRemoteVideoParent.querySelector("video");

            if (existingVideoElement) {
                console.debug(CallApp.tag, "Ui_OnRemoteVideo already contains a video element, skip adding a second one with id:", id, "video:", video);
                return;
            }

            let outerDiv = document.createElement("div")
            outerDiv.className="callapp-remote-video-outer-div";

            let textDiv = document.createElement("div")
            textDiv.appendChild(new Text("connection " + id.id));
            textDiv.className="callapp-remote-video-text-div";
            outerDiv.appendChild(textDiv);

            let videoDiv = document.createElement("div")
            videoDiv.appendChild(video);
            videoDiv.className="callapp-remote-video-container-div";
            outerDiv.appendChild(videoDiv);




            console.debug(CallApp.tag, "adding remote video to element", outerDiv, " for id:", id, " and video:", video);
            this.mUiRemoteVideoParent.appendChild(outerDiv);

            const event = new CustomEvent('webrtc-video-added');
            document.dispatchEvent(event);

        }
    }

    public Ui_OnStartStopButtonClicked = ()=>{
        this.Ui_OnUpdate()
        if(this.mIsRunning) {

            this.Stop();
        }else{
            this.Start(this.mAddress, this.mAudio, this.mVideo);
        }

    }
    public Ui_OnUpdate = ()=>
    {
        console.debug(CallApp.tag, "OnUiUpdate");
        this.mAddress = this.mUiAddress.value;
        this.mAudio  = this.mUiAudio.checked;
        this.mVideo  = this.mUiVideo.checked;
        if (this.mUiUrl) {
            this.mUiUrl.innerHTML = this.GetUrl();
        }
    }

    public Ui_Update() : void
    {
        console.debug(CallApp.tag, "UpdateUi");
        this.mUiAddress.value = this.mAddress;
        this.mUiAudio.checked = this.mAudio ;
        this.mUiVideo.checked = this.mVideo ;
        if (this.mUiUrl) {
            this.mUiUrl.innerHTML = this.GetUrl();
        }
    }

    
    private GenerateRandomKey() {
        let result = "";
        for (let i = 0; i < 7; i++) {
            result += String.fromCharCode(65 + Math.round(Math.random() * 25));
        }
        return result;
    }
    private GetUrlParams() {
        return "?a=" + this.mAddress + "&audio=" + this.mAudio  + "&video=" + this.mVideo  + "&" + "autostart=" + true;
    }
    private GetUrl() {
        return location.protocol + '//' + location.host + location.pathname + this.GetUrlParams();
    }
}



// export function callapp(parent: HTMLElement, iceServers)
export function callapp(config) : CallApp
{
    let callApp : CallApp;
    console.debug(CallApp.tag, "init callapp");
    if(config == null)
    {
        console.error(CallApp.tag + "parent was null");
        return;
    }
    awrtc.SLog.SetLogLevel(awrtc.SLogLevel.Info);
    callApp = new CallApp(config.getIceServers(), config.mediaStream, config.messageHandler);
    callApp.setupUiFromCallback(config);
    return callApp;
}
